.App {
    font-family: arial;

}

section {
    height: auto;
    padding-top: 5%;
    color: red;

}

/* button {
    max-width: 200px;
    text-align: left;
    z-index: 1006;
    display: inline-block;
    cursor: pointer;
    background: transparent;
    color: #ffffff;
    border: none;

    font-weight: bold;
    font-size: 16px;
    padding-left: 100px;
    margin-left: -60px;
}
*/
table {
    margin-top: 3%;

    border: none;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial;
    color: #012850;
    }


table tr {
    background-color: #ccc;
    border: none;

}


table td {
    padding: .625em;
    text-align: left;
    font-size: 15px;

}

table th {
    text-align: center;
    font-size: 1.5vw;
    letter-spacing: 0em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;

}
.youtube {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dsr {
    font-family: Arial;
    color: darkgray;
    margin-left: 0px;
    margin-bottom: 15%;
    text-decoration: none;
    cursor: pointer;
    font-size: 14.5px;
    float: left;
}
.imp {
    padding-left: 25px;
    font-family: Arial;
    color: darkgray;
    margin-left: 0px;
    margin-bottom: 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14.5px;
    float: left;
}
.nub {
    padding-left: 25px;
    font-family: Arial;
    color: darkgray;
    margin-left: 0px;
    margin-bottom: 30px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14.5px;
    float: left;
}

@media screen and (max-width: 800px) {
    table {
        border: 0;
    }

    table th {
        text-align: center;
        font-size: 14px;
        letter-spacing: 0em;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        width: 200px;

    }

    table td {
        font-size: 11px;
    }
}
img.function1 {
    transition: transform 0.8s;

}
img.function1:hover {
    transform: scale(2.3, 2.3);
}
img.function2 {
    transition: transform 0.8s;

}
img.function2:hover {
    transform: scale(1.5, 1.5);
}
.figcapboxf1 {
    width: 27.5%;
    height: auto;
    text-align:center;
    margin-left: 18%;
    font-weight:bold;
    font-size: calc(6px + (14 - 6) * ((100vw - 320px) / (1900 - 320)));
    margin-bottom: 8%;
}
.figcapboxf2 {
    width: 27.5%;
    height: auto;
    text-align:center;
    margin-left: 53.9%;
    font-weight:bold;
    font-size: calc(6px + (14 - 6) * ((100vw - 320px) / (1900 - 320)));
    margin-bottom: 2.5%;
}
.figcapboxf3 {
    width: 27.5%;
    height: auto;
    text-align:center;
    margin-left: 18%;
    font-weight:bold;
    font-size: calc(6px + (14 - 6) * ((100vw - 320px) / (1900 - 320)));
    margin-bottom: 1.5%;
}
.figcapboxf4 {
    width: 27.5%;
    height: auto;
    text-align:center;
    margin-left: 53.9%;
    font-weight:bold;
    font-size: calc(6px + (14 - 6) * ((100vw - 320px) / (1900 - 320)));
}
.figcapboxf5 {
    width: 27.5%;
    height: auto;
    text-align:center;
    margin-left: 18%;
    font-weight:bold;
    font-size: calc(6px + (14 - 6) * ((100vw - 320px) / (1900 - 320)));

}